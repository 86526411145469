var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('DataTable', {
    attrs: {
      "options": _vm.options
    }
  }), _c('vx-dialog', {
    attrs: {
      "title": "Select feature",
      "width": "400",
      "actions": ""
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.$add('attribute', {
          id: _vm.featureId
        });
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.features,
      "item-text": "name",
      "item-value": "_id",
      "required": true
    },
    model: {
      value: _vm.featureId,
      callback: function callback($$v) {
        _vm.featureId = $$v;
      },
      expression: "featureId"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }